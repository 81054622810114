import React, { useEffect, useContext } from 'react';
import { graphql, Script } from 'gatsby';
import styled from 'styled-components';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { GlobalContext } from '../components/GlobalContext';

import bp from '../assets/js/breakpoints';

import Seo from '../components/Seo';

const EventsPage = ({ data }) => {
  const document = data.prismicEvents;
  const { title } = document.data;

  const { setStoryHeaderColor, setPageBgColor } = useContext(GlobalContext);

  useEffect(() => {
    setPageBgColor('var(--color-snow)');
    setStoryHeaderColor('var(--color-snow)');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Intro>
        <Title>{title}</Title>
      </Intro>

      <div id="CitySpark" />
      <Script src="https://portal.cityspark.com/PortalScripts/HeyBay" />
    </Wrapper>
  );
};

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({ data }) => (
  <Seo
    title={data.prismicEvents.data.title}
    description={data.prismicEvents.data.meta_description}
    image={data.prismicEvents.data.meta_image.url}
  />
);

export default withPrismicPreview(EventsPage);

/**
 * Styled Components
 *
 */
const Wrapper = styled.div`
  padding-top: 12rem;
`;

const Intro = styled.section`
  position: relative;
  width: 100%;
  max-width: 88rem;
  padding: var(--space-l) 0 var(--space-xxs);
  border-bottom: 0.1rem solid ${props => props.contrastingColor};
  padding-bottom: var(--space-xl);

  @media (${bp.min.xs_sm}) {
    border-bottom: 0;
    padding: var(--space-xl) 0 0;
    margin: 0 auto;
    padding-bottom: 4.8rem;
  }
`;

const Title = styled.h1`
  font-size: clamp(4.4rem, 8vw, 9rem);
  line-height: 1.1;
  font-weight: var(--font-weight-bold);
  width: 100%;
  letter-spacing: -0.04em;

  @media (${bp.min.sm_md}) {
    line-height: 0.88;
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query {
    prismicEvents {
      _previewable
      data {
        title
        meta_description
        meta_image {
          url
        }
      }
    }
  }
`;
